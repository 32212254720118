import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/0-layout"
import Query from "../components/query"
import SEO from "../components/seo"
import "../scss/privacy.scss"

/**
 * @property {object} allPrivacyJson
 * @property {object} policy
 * @property {object} handling
 * @property {object} owned
 * @property {object} articles
 */

const Privacy = () => {
    const data = Query()

    const dataPrivacy = useStaticQuery(graphql`
      query PrivacyQuery {
          allPrivacyJson {
              edges {
                  node {
                      policy {
                          articles {
                              b
                              p
                          }
                      }
                      handling {
                          articles {
                              b
                              p
                              li
                          }
                      }
                      owned {
                          articles {
                              b
                              p
                              li {
                                  s
                                  ul
                              }
                          }
                      }
                      method {
                          articles {
                              b
                              p
                              li {
                                  s
                                  ul
                              }
                          }
                      }
                  }
              }
          }
      }
  `)

    let policyItems = []
    dataPrivacy.allPrivacyJson.edges[0].node.policy.articles.forEach(i => {
        policyItems.push(
            <li style={{ whiteSpace: "pre-line" }}>
                <b>{i.b}</b><br />{i.p}
            </li>,
        )
    })

    let tables = [
        ["基本方針の策定", "個人データの適正な取扱いの確保のため、「関係法令・ガイドライン等の遵守」、「質問及び苦情処理の窓口」等についての基本方針として、本プライバシーポリシーを策定"],
        ["個人データの取扱いに係る規律の整備", "個人データの取得、利用、保存等を行う場合の基本的な取扱方法を整備"],
        ["組織的安全管理措置", "1）整備した取扱方法に従って個人データが取り扱われていることを責任者が確認\n2）従業者から責任者に対する報告連絡体制を整備"],
        ["人的安全管理措置", "1）個人データの取扱いに関する留意事項について、従業者に定期的な研修を実施\n2）個人データについての秘密保持に関する事項を就業規則に記載"],
        ["物理的安全管理措置", "1）個人データを取り扱うことのできる従業者及び本人以外が容易に個人データ を閲覧できないような措置を実施\n2）個人データを取り扱う機器、電子媒体及び書類等の盗難又は紛失等を防止するための措置を講じるとともに、事業所内の移動を含め、当該機器、電子媒体等を持ち運ぶ場合、容易に個人データが判明しないよう措置を実施"],
        ["技術的安全管理措置", "1）個人データを取り扱うことのできる機器及び当該機器を取り扱う従業者を明確化し、個人データへの不要なアクセスを防止\n2）個人データを取り扱う機器を外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入"],
        ["外的環境の把握", "個人データの保管を委託している事業者が米国の事業者であり、個人データの保存先が米国となる可能性があることから、米国における個人情報の保護に関する制度を把握した上で安全管理措置を実施"]
    ]
    let handlingItems = []
    dataPrivacy.allPrivacyJson.edges[1].node.handling.articles.forEach(i => {
        let tmpLi = []
        if (i.b === "利用目的" && i.li.length > 0) {
            i.li.forEach(l =>
                tmpLi.push(
                    <li style={{ whiteSpace: "pre-line" }}>{l}</li>,
                ),
            )
        }
        if (i.b === "個人情報の安全管理") {
            tables.forEach(l =>
                tmpLi.push(
                    <tr>
                        <td>{l[0]}</td>
                        <td>{l[1]}</td>
                    </tr>
                ))
        }

        let tmpP = []
        i.p.forEach(p => tmpP.push(p + "\n"))

        if (i.b !== "利用目的" && i.b !== "個人情報の安全管理") {
            handlingItems.push(
                <li style={{ whiteSpace: "pre-line" }}>
                    <b>{i.b}</b><br />{tmpP}

                    <ol className={"parentheses"}>
                        {tmpLi}
                    </ol>
                </li>,
            )
        }

        if (i.b === "利用目的") {
            handlingItems.push(
                <li style={{ whiteSpace: "pre-line" }}>
                    <b>{i.b}</b><br />{tmpP}

                    <ol id="kazyouGaki" className={"parentheses"}>
                        {tmpLi}
                    </ol>
                </li>,
            )
        }

        if (i.b === "個人情報の安全管理") {
            handlingItems.push(
                <li style={{ whiteSpace: "pre-line" }}>
                    <b>{i.b}</b><br />{tmpP}

                    <table id="tables" cellPadding={0} cellSpacing={0}>
                        {tmpLi}
                    </table>
                </li>,
            )
        }
    })

    const getOwnedItems = (v) => {
        let items = []

        v.articles.forEach(i => {
            let tmpLi = []
            if (i.li.length > 0) {
                i.li.forEach(l => {

                    let tmpUL = []
                    l.ul.forEach(u => {
                        tmpUL.push(
                            <li>{u}</li>,
                        )
                    })

                    tmpLi.push(
                        <li style={{ whiteSpace: "pre-line" }}>
                            {l.s}
                            <ul>{tmpUL}</ul>
                        </li>,
                    )
                })
            }

            let tmpP = []
            i.p.forEach(p => tmpP.push(p + "\n"))

            items.push(
                <li style={{ whiteSpace: "pre-line" }}>
                    <b>{i.b}</b><br />{tmpP}
                    <ol className={"parentheses"}>
                        {tmpLi}
                    </ol>
                </li>,
            )
        })

        return items
    }

    return (
        <Layout sitePath={"privacy"} pageTitle={data.allFooterJson.edges[4].node.li}>

            <SEO title={data.allFooterJson.edges[4].node.li} />

            <section>
                <h1>〈プライバシーポリシー〉</h1>
                <h2>個人情報保護方針</h2>
                <ol>{policyItems}</ol>

                <p>
                    <table>
                        <tr>
                            <td>平成</td>
                            <td style={{textAlign: "right"}}>29年</td>
                            <td style={{textAlign: "right"}}>6月</td>
                            <td>20日 制定</td>
                        </tr>

                        <tr>
                            <td>令和</td>
                            <td style={{textAlign: "right"}}>4年</td>
                            <td style={{textAlign: "right"}}>9月</td>
                            <td>30日 改定</td>
                        </tr>
                    <tr>
                            <td>令和</td>
                            <td style={{textAlign: "right"}}>5年</td>
                            <td style={{textAlign: "right"}}>7月</td>
                            <td>24日 改定</td>
                        </tr>
                    </table>

                <p>中央海産株式会社 代表取締役 田中 文敏</p>
                </p>
            </section>

            <hr />

            <section>
                <h1>〈当社における個人情報のお取扱いについて〉</h1>
                <h2>当社では個人情報を、以下の通りお取り扱いいたします。</h2>
                <ol>{handlingItems}</ol>
            </section>

            <hr />

            <section>
                <h1>〈当社が保有する開示対象個人情報について〉</h1>
                <h2>(個人情報保護法及び JIS に基づく「本人の知り得る状態」に置く事項<br />
                    （本人の求めに応じて遅滞なく回答する場合を含む）</h2>
                <ol>
                    {getOwnedItems(dataPrivacy.allPrivacyJson.edges[2].node.owned)}
                </ol>

                <ol id={"method"}>
                    {getOwnedItems(dataPrivacy.allPrivacyJson.edges[3].node.method)}
                </ol>
            </section>

            <hr />

            <section>
                <ul className={"asterisk"}>
                    <li>
                        <b>「公的証明書」とは</b><br />
                        運転免許証、各種健康保険証・年金手帳等、写真付き、住民基本台帳カード、旅券（パスポート）、外国人登録証明書、印鑑登録証明書、住民票の写し、戸籍謄本・抄本、外国人登録原票の写しなどを言います。なお、送付頂いた書類は原則としてご返却いたしません。
                    </li>

                    <li>
                        <b>注意事項</b><br />
                        郵送や配送途中の書類の紛失、事故による未着につきましては、当社では責任を負いかねます。必要事項を全てご記入下さい。書類に不備がある場合は、返送させていただく場合がございます。個人情報保護法の例外規定にあたる場合など、開示等の求めにお答えできない場合がございます。内容により、お時間をいただく場合がございます。開示等の求めにともない収集した個人情報は、開示等の求めの必要な範囲のみで取扱うものとします。提出していただいた書面は当社で適切に廃棄いたします。「訂正等」、「利用停止等」の結果、該当するサービスがご利用いただけなくなることがあります。あらかじめご了承下さい。
                    </li>
                </ul>
            </section>

            <hr />

            <section>
                <h1>〈個人情報苦情及び相談窓口〉</h1>
                <h2>中央海産株式会社 総務部</h2>
                <p>
                    〒400-0043 山梨県甲府市国母 8丁目 18番 7号<br />
                    TEL：055-220-7300<br />
                    Email：privacy@seasonbox.jp<br />
                    受付時間：9:00～16:00（土日、祝日、年末年始は除く）
                </p>
            </section>

        </Layout>
    )
}

export default Privacy
